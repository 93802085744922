html {
  background: black url(https://i.imgur.com/ydjY458.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  opacity:0.9;
  background-size: cover;
  font-family: roboto;
  z-index:-10;
  overflow: hidden;
  height:100%;
}
body {
  font-family: 'Roboto', sans-serif;
  margin:0;
  height:100%;
  overflow-y:scroll;
  -webkit-overflow-scrolling: touch;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid rgba(255, 177, 10,0.8);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgba(255, 177, 10,0.8) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.flag-icon  {
  background-size: cover !important;
  box-sizing: border-box !important;
}
.flag-icon.flag-icon-squared {
  width: 100% !important;
  height: 100% !important;
  margin-right: 5px;
}
.flag-icon-squared {
  width: 100%  !important;
  height: 100% !important;
}

.driver-flags {
  width: 100%;
  height: 100%;
}
